import React, { Component, PureComponent } from 'react';
import './index.scss'
import {NavLink, Link} from 'react-router-dom'
import AboutUsPage from './About'

import mobilecheck from './mobilecheck'

const IS_MOBILE = mobilecheck()

export const customOrderAppPaths = ["/menu", "/order", "/"]

export {AboutUsPage as about}


export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu"},
  // {path: "/about", label: "Our Concept"},
  // {path: "/about", label: "Catering"}
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label}) => (
          <li><NavLink className="page-link" exact to={path}>{label}</NavLink></li>
        ))}
      </ul>
    </nav>
  )}
}


export class Header extends Component {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">
          <div class="video-container">
            {<div className="fire-bg">

            </div>}

            {/* {!IS_MOBILE && <video autoPlay loop muted className="video-bg" id="video-bg">
              <source src="https://media.istockphoto.com/videos/flaming-stir-fry-slow-motion-video-id506692559"
              type="video/mp4" alt="HTML5 background video" />
              <source src="https://media.istockphoto.com/videos/flaming-stir-fry-slow-motion-video-id506692559"
                type="video/webm" alt="HTML5 background video" />
            </video>} */}
            <div className="splash">
              <div className="section-header">
                {/* <p className="decorative">Welcome to</p> */}
                <h2>Chinese Delight</h2>
                <p className="catch-phrase">Real Chinese Food For Real People</p>
                {/* <p className="catch-phrase"><span className="specials">$5 Off Orders of $60! (before tax)</span></p> */}
                {/* <p>family owned</p> */}
                <Link to="/order" className="order-online-link">Order Now</Link>
                <p>Togo & Delivery</p>
              </div>
            </div>
          </div>





            {/* <article className="landing-section delicious-menu">
              <div className="section-header">
                <p className="decorative"></p>
                <h2>About Us</h2>
                <div className="content">
                  <p>lorem Ipsum</p>
                  <Link to="/menu" className="landing-section-link">View the full menu</Link>
                </div>
              </div>
            </article> */}



        </div>
      </div>
    );
  }
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header-wrapper">
        <div className="ordering-header">

          <div
            className="text"
             >
            <h2>Start your order below</h2>
            <p></p>
          </div>
        </div>
      </div>
    );
  }
}


export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  // const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  // const minutes = now.getMinutes() // 0 to 59
  // const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const lunchHours = (
    (hours < 15)
  ) || (hours >= 22)

  const isLunchMenu = tag.indexOf('lunch') !== -1     // m-f 11am-3pm

  if (isLunchMenu) { 
    if (lunchHours) {
      return true
    }
    return false 
  }
  // if (isDinnerSubmenu && !showLunchMenu) { return true }

  return true
}

import * as Components from "./Chinesedelight"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "chinesedelight"
}

